import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import SessionProvider from './providers/session.provider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const client = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <SessionProvider>
      <QueryClientProvider client={client}>
        <App/>
        <ReactQueryDevtools position="bottom-right"/>
        <ToastContainer/>
      </QueryClientProvider>
    </SessionProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

