import React, { useEffect } from 'react'
import { useSession } from '../providers/session.provider'
import { FullscreenSplash } from '../components/loading/fullscreen-splash'
import { query } from '../utils/query'
import { Me, MeQuery } from '../generated/graphql'
import { auth0Client } from '../utils/auth0'

export const AuthSection: React.FC = () => {
  const [, setSession] = useSession()

  const triggerLogin = async () => {
    const client = await auth0Client()
    await client.loginWithRedirect({
      redirect_uri: window.location.origin,
    })
  }

  const generateToken = async () => {
    const client = await auth0Client()
    await client.handleRedirectCallback()
    localStorage.token = await client.getTokenSilently()
    window.history.replaceState({}, document.title, '/')
    return authenticateToken()
  }

  const authenticateToken = async () => {
    try {
      const { me } = await query<MeQuery>(Me)
      if (!me) return triggerLogin()
      setSession(me)
    } catch (e) {
      localStorage.removeItem('token')
      return triggerLogin()
    }
  }

  useEffect(() => {
    const query = window.location.search
    if (query.includes('code=') && query.includes('state=')) {
      try {
        generateToken()
      } catch (e) {
        triggerLogin()
      }
      return
    }

    if (!localStorage.token) {
      triggerLogin()
      return
    }

    try {
      authenticateToken()
    } catch (e) {
      localStorage.removeItem('token')
      triggerLogin()
    }
  }, [])

  return <FullscreenSplash fade icon="user"/>
}