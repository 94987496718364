import { RequestDocument } from 'graphql-request/dist/types'
import { query } from './query'
import { notify } from './notify'

export const upsert = async (
  id: string | null,
  input: Record<string, any>,
  createQuery: RequestDocument,
  updateQuery: RequestDocument,
  onSuccess?: () => void,
  onError?: (e: unknown) => void,
) => {
  try {
    if (!id) await query(createQuery, { input })
    if (id) await query(updateQuery, { id, input })
    notify.success()
    if (onSuccess) onSuccess()
  } catch (e) {
    notify.error()
    console.error('Upsert error', e)
    if (onError) return onError(e)
  }
}