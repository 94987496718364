import React, { MouseEventHandler } from 'react'

export type ButtonStyle =
  | 'dark'
  | 'danger'
  | 'positive'
  | 'plain'

type Props = {
  icon?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  label?: string
  disabled?: boolean
  type?: 'submit' | 'reset' | 'button'
  style?: ButtonStyle
  className?: string
  round?: boolean
  id?: string
}

const styles: Record<ButtonStyle, string> = {
  dark: 'text-white bg-dark focus:ring-dark',
  danger: 'text-white bg-red-600 focus:ring-red-600',
  positive: 'text-white bg-green-600 focus:ring-green-600',
  plain: 'text-dark font-medium hover:ring hover:ring-dark focus:ring-dark shadow-none',
}

export const Button: React.FC<Props> = ({
  icon, label, onClick, type, disabled,
  style, className, round, id,
}) => (
  <button
    className={`${className || ''} 
                inline-flex justify-center items-center px-4 py-1
                border border-transparent
                shadow-sm text-base font-medium
                hover:opacity-75
                ${round ? 'rounded-full' : 'rounded-md '}
                ${(round && icon && !label) ? 'w-8 h-8 px-0' : ''}
                ${style ? styles[style] : styles['dark']}
                focus:outline-none focus:ring-2 focus:ring-offset-2`}
    id={id}
    disabled={disabled}
    type={type || 'button'}
    onClick={onClick}>
    {icon && <i className={`${label ? 'mr-4' : ''} fas fa-${icon}`}/>}
    {label}
  </button>
)