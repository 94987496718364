import { LoaderFn } from 'react-location'
import { LocationGenerics } from '../utils/routes'
import { Client, GetClient } from '../generated/graphql'
import { query } from '../utils/query'

export const getClientLoader: LoaderFn<LocationGenerics> = async (
  { params: { id } },
) => {
  try {
    const response = await query<{client: Client}>(GetClient, { id })
    return { client: response.client }
  } catch (e) {
    console.error('Failed to retrieve client', e)
  }
  return { client: undefined }
}