import React from 'react'

const style = `
  font-medium text-gray-700 block mb-1
`

type Props = {
  target: string
  tabIndex?: number
}

export const Label: React.FC<Props> = ({ target, tabIndex, children }) => (
  <label tabIndex={tabIndex || -1} htmlFor={target}
         className={style}>{children}</label>
)