import React from 'react'
import { Link, useMatches } from 'react-location'
import { SidebarItem } from './sidebar-item'
import { SidebarLogout } from './sidebar-logout'
import { LocationGenerics } from '../../utils/routes'

export type SidebarItemType = {
  label: string
  icon: string
  path: string
}

const items: SidebarItemType[] = [
  { label: 'Proyectos', icon: 'diagram-project', path: '/proyectos' },
  { label: 'Clientes', icon: 'people-pants-simple', path: '/clientes' },
  { label: 'Facturas', icon: 'file-invoice', path: '/facturas' },
  { label: 'Gastos', icon: 'funnel-dollar', path: '/gastos' },
]

type Props = {
  onNavigation: () => void
}

export const SidebarContent: React.FC<Props> = ({ onNavigation }) => {
  const matches = useMatches<LocationGenerics>()

  const isActive = (item: SidebarItemType): boolean => {
    return Boolean(matches.find(match => {
      return match.pathname === item.path
    }))
  }

  return (
    <div className="flex flex-col flex-grow bg-dark overflow-y-auto">
      <Link to="/" className="py-5">
        <img className="h-12 mx-auto" src="/logo.svg" alt=""/>
      </Link>
      <div className="p-4 grow">
        {items.map(item => (
          <Link key={item.path} to={item.path}
                onClick={onNavigation}>
            <SidebarItem item={item} active={isActive(item)}/>
          </Link>
        ))}
      </div>
      <div className="mt-auto p-4">
        <SidebarLogout/>
      </div>
    </div>
  )
}
