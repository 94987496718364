const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', (e) => {
      if (!e.target?.result) return reject()
      resolve(e.target.result as ArrayBuffer)
    })
    reader.readAsArrayBuffer(file)
  })
}

export const hashFile = async (file: File): Promise<string> => {
  const arrayBuffer = await fileToArrayBuffer(file)
  const buffer = await crypto.subtle.digest('SHA-256', arrayBuffer)
  const hashArray = Array.from(new Uint8Array(buffer))
  return hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
}