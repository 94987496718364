import React from 'react'
import { SidebarItemType } from './sidebar-content'

type Props = {
  item: SidebarItemType
  active?: boolean
}

export const SidebarItem: React.FC<Props> = ({ item, active }) => (
  <div className={`text-white cursor-pointer
                   flex items-center mt-1
                   transition-colors duration-200
                   hover:bg-gray-600 px-4 py-2 rounded
                   ${active ? 'bg-gray-600' : ''}`}>
    <i className={`w-8 mr-4 align-middle text-center opacity-50
                   fas text-2xl fa-${item.icon}`}/>
    {item.label}
  </div>
)