import React, { useState } from 'react'
import { useMatch, useNavigate } from 'react-location'
import { Section } from '../components/sections/section'
import { Title } from '../components/sections/title'
import { TextInput } from '../components/input/text-input'
import { FormBox } from '../components/forms/form-box'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Client,
  ClientCreate,
  ClientDelete,
  ClientUpdate,
} from '../generated/graphql'
import { LocationGenerics } from '../utils/routes'
import { prune } from '../utils/prune'
import { upsert } from '../utils/upsert'
import { handleDelete } from '../utils/handle-delete'
import { AvatarUpload } from '../components/input/avatar-upload'

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Demasiado corto')
    .max(255, 'Demasiado largo'),
  email: Yup.string().email('Debe ser email'),
  logo: Yup.string().nullable(),
})

const initialValues = {
  id: '',
  name: '',
  email: '',
  logo: '',
}

export const ClientsForm: React.FC = () => {
  const navigate = useNavigate<LocationGenerics>()
  const [loading, setLoading] = useState(false)
  const { params: { id }, data: { client } } = useMatch<LocationGenerics>()
  const form = useFormik<Client>({
    validationSchema,
    initialValues,
    validateOnBlur: true,
    async onSubmit(client: Client) {
      setLoading(true)
      await upsert(
        client.id,
        prune(client, ['id']),
        ClientCreate, ClientUpdate,
        () => navigate({ to: '/clientes' }),
        () => setLoading(false),
      )
    },
  })

  const onDelete = async () => {
    setLoading(true)
    await handleDelete(
      ClientDelete, { id },
      () => navigate({ to: '/clientes' }),
      () => setLoading(false),
    )
  }

  // Replace form values if a client is found
  if (client?.id && !form.values.id) {
    form.setValues({
      ...client,
      logo: client.logo || '',
    })
  }

  return (
    <Section key={id}>
      <Title>
        {id ? 'Editar cliente' : 'Nuevo cliente'}
      </Title>
      <FormBox onSave={form.handleSubmit} onDelete={onDelete}
               showDelete={client?.id} disabled={loading}>
        <TextInput required label="Nombre"
                   error={form.errors.name}
                   {...form.getFieldProps('name')}/>
        <TextInput required type="email" label="Email"
                   error={form.errors.email}
                   {...form.getFieldProps('email')}
                   name="email"/>
        <AvatarUpload label="Logo" {...form.getFieldProps('logo')}/>
      </FormBox>
    </Section>
  )
}