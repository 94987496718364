import React from 'react'
import { Title } from '../components/sections/title'
import { Client, useGetClientsQuery } from '../generated/graphql'
import { ClientsTable } from '../tables/clients.table'
import { Button } from '../components/buttons/button'
import { Section } from '../components/sections/section'
import { useNavigate } from 'react-location'
import { LocationGenerics } from '../utils/routes'
import { Loading } from '../components/loading/loading'

export const ClientsSection: React.FC = () => {
  const navigate = useNavigate<LocationGenerics>()
  const { data, isLoading } = useGetClientsQuery()

  return (
    <Section>
      <div className="flex justify-between items-baseline">
        <Title>
          Clientes
        </Title>
        <Button label="Agregar" onClick={() => {
          navigate({ to: '/clientes/nuevo' })
        }}/>
      </div>
      {isLoading && <Loading/>}
      {data?.clients?.length && (
        <ClientsTable data={data.clients as Client[]}/>
      )}
    </Section>
  )
}