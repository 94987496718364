export const prune = (
  data: Record<string, any>,
  fields: string[],
) => {
  const pruned: Record<string, any> = {}
  for (const key of Object.keys(data)) {
    if (fields.includes(key)) continue
    pruned[key] = data[key]
  }
  return pruned
}