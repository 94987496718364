import React from 'react'
import { SmallLoading } from '../loading/small-loading'

export type AvatarSize = 'regular' | 'big'

type Props = {
  src: string
  className?: string
  style?: AvatarSize
  uploading?: boolean
}

const styles: Record<AvatarSize, string> = {
  regular: 'w-8 h-8',
  big: 'w-12 h-12',
}

const defaultAvatar = 'https://lotus-mx.sfo3.digitaloceanspaces.com/2ceac6d394a19b78b6824c7415423d6a428fbd61c1e81bb5f0c394f282325918.png'

export const Avatar: React.FC<Props> = ({
  src, className, style, uploading,
}) => (
  <div className={className}>
    {uploading ? (
      <div className={`
           ${styles[style || 'regular']} 
           cover rounded-md bg-dark text-white
           flex items-center
         `}>
        <SmallLoading/>
      </div>
    ) : (
      <img alt="Avatar" src={src || defaultAvatar}
           className={`
           ${styles[style || 'regular']} 
           cover rounded-md
         `}/>
    )}
  </div>
)