import { toast, ToastOptions } from 'react-toastify'

const defaults: Partial<ToastOptions> = {
  position: 'bottom-center',
  hideProgressBar: true,
}

export const notify = {
  success(message?: string) {
    message = message || 'Operación realizada con éxito! 🎉'
    toast(message, { type: 'success', ...defaults })
  },
  error(message?: string) {
    message = message || 'Hubo un problema 😕'
    toast(message, { type: 'error', ...defaults })
  },
}