import React, { HTMLInputTypeAttribute } from 'react'
import { Label } from './label'
import { FieldInputProps } from 'formik'

const style = `
  shadow-sm focus:ring-dark focus:border-dark 
  block w-full sm:text-sm 
  border border-gray-300 rounded-md
  p-2 outline-dark text-dark
`

type Props = {
  name: string
  label: string
  required?: boolean
  className?: string
  type?: HTMLInputTypeAttribute
  error?: string
} & FieldInputProps<string>

export const TextInput: React.FC<Props> = ({
  label, className, name, required, type, error, ...props
}) => (
  <div className="pb-6 relative">
    <Label target={name}>{label}</Label>
    <input {...props} id={name} name={name}
           required={required} type={type || 'text'}
           className={`${style} ${className}`}/>
    <div className={`absolute block font-bold 
                     transition-opacity duration-500
                     ${error ? 'opacity-100' : 'opacity-0'}
                     text-sm bottom-0 text-red-600`}>
      {error}
    </div>
  </div>
)