import React from 'react'
import { useSession } from './providers/session.provider'
import { AuthSection } from './sections/auth.section'
import { DashboardSection } from './sections/dashboard.section'
import { NoRoleSection } from './sections/no-role.section'

function App() {
  const [session] = useSession()

  // If there's a session load the router
  // otherwise go to the screen that handles auth
  if (!session.id) return <AuthSection/>
  if (session.role === 'NONE') return <NoRoleSection/>
  return <DashboardSection/>
}

export default App
