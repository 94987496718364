import { MakeGenerics, ReactLocation, Route } from 'react-location'
import { HomeSection } from '../sections/home.section'
import { ProjectsSection } from '../sections/projects.section'
import { ClientsSection } from '../sections/clients.section'
import { ExpensesSection } from '../sections/expenses.section'
import { InvoicesSection } from '../sections/invoices.section'
import { ClientsForm } from '../forms/clients.form'
import { Client } from '../generated/graphql'
import { getClientLoader } from '../loaders/get-client.loader'

export type LocationGenerics = MakeGenerics<{
  LoaderData: {
    client: Client,
  }
  Params: {
    id: string
  }
}>

export const location = new ReactLocation<LocationGenerics>()

export const clientChildren: Route<LocationGenerics>[] = [
  { path: '/', element: <ClientsSection/> },
  { path: '/nuevo', element: <ClientsForm/> },
  { path: '/:id/editar', element: <ClientsForm/>, loader: getClientLoader },
]

export const routes: Route<LocationGenerics>[] = [
  { path: '/', element: <HomeSection/> },
  { path: '/proyectos', element: <ProjectsSection/> },
  { path: '/clientes', children: clientChildren },
  { path: '/facturas', element: <InvoicesSection/> },
  { path: '/gastos', element: <ExpensesSection/> },
]