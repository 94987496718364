import React from 'react'
import { Button } from '../buttons/button'
import { DeleteButton } from '../buttons/delete-button'

type Props = {
  onSave?: () => void
  onDelete?: () => void
  disabled?: boolean
  showDelete?: boolean
}

export const FormBox: React.FC<Props> = ({
  onSave, disabled, children, showDelete, onDelete,
}) => {

  return <form onSubmit={onSave}>
    <div className="shadow overflow-hidden sm:rounded-md">
      <div className="
      px-4 py-5 bg-white sm:p-6
      grid md:grid-cols-2 gap-x-6 gap-y-2
    ">
        {children}
      </div>
      <div className="px-4 py-3 bg-gray-100 text-right sm:px-6">
        {showDelete && onDelete && (
          <DeleteButton className="mr-4" onDelete={onDelete}/>
        )}
        <Button disabled={disabled}
                label="Guardar" type="submit"/>
      </div>
    </div>
  </form>
}