import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { Client } from '../generated/graphql'
import { LabeledAvatar } from '../components/elements/labeled-avatar'
import { SimpleTable } from '../components/table/simple-table'
import { useNavigate } from 'react-location'

type Props = {
  data: Client[]
}

type ColumnFields = {
  id: string
  name: string
  logo?: string | null
}

export const ClientsTable: React.FC<Props> = ({ data }) => {

  const navigate = useNavigate()

  const columns = useMemo<Column<ColumnFields>[]>(() => [
    {
      Header: 'Nombre',
      accessor: row => {
        return <LabeledAvatar label={row.name}
                              avatar={row.logo}/>
      },
      id: 'name',
    },
  ], [])

  const instance = useTable<ColumnFields>({ data, columns })

  return <SimpleTable
    instance={instance}
    onRowEdit={id => navigate({ to: `/clientes/${id}/editar` })}
  />
}