import { ChangeEventHandler } from 'react'
import { query } from './query'
import {
  GenerateSignedUrl,
  GenerateSignedUrlMutation,
} from '../generated/graphql'
import { notify } from './notify'
import { hashFile } from './hash-file'

export const signedUpload = (
  extensions: string[], onUpload: (url: string) => void,
): ChangeEventHandler<HTMLInputElement> => async (e) => {
  if (!e.target.files?.length) return
  const file = e.target.files[0]
  // Get a signed URL
  const fileParts = file.name.split('.')
  const extension = fileParts[fileParts.length - 1].toLowerCase()
  if (!extensions.includes(extension)) {
    return notify.error('La extensión debe ser ' + extensions.join(' o '))
  }

  const hash = await hashFile(file)
  const key = `${hash}.${extension}`

  const result = await query<GenerateSignedUrlMutation>
  (GenerateSignedUrl, { key })

  const url = result.generateSignedURL
  if (!url) {
    return notify.error('Error al iniciar subida')
  }

  await fetch(url, {
    method: 'put',
    body: file,
    headers: {
      'Content-Type': file.type,
      'x-amz-acl': 'public-read',
    },
  })

  onUpload(process.env.REACT_APP_SPACES_URL + '/' + key)
}
