import React, { useState } from 'react'
import { Button } from './button'

type Props = {
  onDelete: () => void
  disabled?: boolean
  className?: string
}

export const DeleteButton: React.FC<Props> = ({
  onDelete, disabled, className,
}) => {
  const [showConfirm, setShowConfirm] = useState(false)

  if (!showConfirm) return (
    <Button
      className={className}
      disabled={disabled}
      style="danger"
      label="Eliminar"
      onClick={() => setShowConfirm(true)}
    />
  )

  return (
    <div className={`${className} inline-flex items-center`}>
      <div className="text-dark">Eliminar?</div>
      <Button icon="times" style="danger" round
              className="mx-2"
              onClick={() => setShowConfirm(false)}/>
      <Button icon="check" style="positive" round
              onClick={() => {
                setShowConfirm(false)
                onDelete()
              }}/>
    </div>
  )
}
