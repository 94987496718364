import React, { createContext, FC, useContext, useState } from 'react'
import { Role } from '../generated/graphql'

export type Session = {
  id: string,
  email: string,
  name: string,
  role: Role
}
type ContextState = [Session, (value: Session) => void]

export const emptySession = (): Session => ({
  id: '',
  email: '',
  name: 'Nomen',
  role: 'NONE',
})

const SessionContext = createContext<ContextState>([emptySession(), () => null])

const SessionProvider: FC = ({ children }) => {
  const state = useState<Session>(emptySession())

  return (
    <SessionContext.Provider value={state}>
      {children}
    </SessionContext.Provider>
  )
}

export const useSession = () => useContext(SessionContext)

export default SessionProvider