import React from 'react'
import { Avatar } from './avatar'


type Props = {
  className?: string
  label: string
  sublabel?: string
  avatar?: string | null
}

export const LabeledAvatar: React.FC<Props> = ({
  className, label, sublabel, avatar,
}) => (
  <div className={`flex items-center ${className}`}>
    {avatar && <Avatar className="mr-4" src={avatar}/>}
    <div>
      <div className="font-medium">{label}</div>
      {sublabel &&
        <div>{sublabel}</div>
      }
    </div>
  </div>
)