import React, { useState } from 'react'
import { FullscreenSplash } from '../components/loading/fullscreen-splash'
import { emptySession, useSession } from '../providers/session.provider'
import { auth0Client } from '../utils/auth0'
import { Button } from '../components/buttons/button'

export const NoRoleSection: React.FC = () => {
  const [, setSession] = useSession()
  const [processing, setProcessing] = useState(false)

  const logout = async () => {
    setProcessing(true)
    const auth0 = await auth0Client()
    localStorage.removeItem('token')
    await auth0.logout()
    setProcessing(false)
    setSession(emptySession())
  }

  return (
    <FullscreenSplash spin={processing}
                      icon={processing ? 'spinner-third' : 'times'}>
      {!processing && (
        <div className="text-center text-white">
          <div className="opacity-75">
            Tu usuario no cuenta con acceso al panel
          </div>
          <Button onClick={logout} label="Cerrar sesión"/>
        </div>
      )}
    </FullscreenSplash>
  )
}