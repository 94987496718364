import request from 'graphql-request'
import { RequestDocument, Variables } from 'graphql-request/dist/types'

if (!process.env.REACT_APP_GRAPHQL_ENDPOINT) {
  throw 'GraphQL endpoint not set!'
}

export const query = <T, V = Variables>(
  query: RequestDocument,
  variables?: V,
): Promise<T> => {
  const headers = localStorage.token
    ? { authorization: `Bearer ${localStorage.token}` }
    : undefined

  const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT!

  return request<T, V>(endpoint , query, variables, headers)
}

// Used for react query
export const contextQuery = <T, V = Variables>(
  query: RequestDocument,
  variables?: V,
): () => Promise<T> => {
  const headers = localStorage.token
    ? { authorization: `Bearer ${localStorage.token}` }
    : undefined

  const endpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT!

  return () => request<T, V>(endpoint , query, variables, headers)
}
