import React from 'react'
import { SidebarContent } from './sidebar-content'
import { Dialog } from '@headlessui/react'

type Props = {
  open: boolean
  setOpen: (state: boolean) => void
}

export const Sidebar: React.FC<Props> = ({ open, setOpen }) => {
  return (
    <>
      {/* Mobile version */}
      <Dialog onClose={() => setOpen(false)} open={open} className="md:hidden">
        <Dialog.Overlay className="fixed inset-0 bg-dark bg-opacity-75"/>
        <div className="flex w-64 fixed bg-dark inset-y-0">
          <SidebarContent onNavigation={() => setOpen(false)}/>
          <button className="fixed top-0 right-0 p-4 m-2 text-white"
                  onClick={() => setOpen(false)}>
            <i className="fas fa-times fa-2x"/>
          </button>
        </div>
      </Dialog>

      <div className="hidden md:flex w-64 fixed bg-dark inset-y-0">
        <SidebarContent onNavigation={() => null}/>
      </div>
    </>
  )
}