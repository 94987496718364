import React, { ChangeEventHandler, useState } from 'react'
import { Label } from './label'
import { FieldInputProps } from 'formik'
import { Avatar } from '../elements/avatar'
import { signedUpload } from '../../utils/signed-upload'
import { notify } from '../../utils/notify'

type Props = {
  name: string
  label: string
  required?: boolean
  className?: string
  error?: string
} & FieldInputProps<string>

export const AvatarUpload: React.FC<Props> = ({
  label, className, name, value, onChange,
}) => {
  const [uploading, setUploading] = useState(false)

  let inputRef: HTMLInputElement | null

  const click = () => inputRef?.click()

  const finishUpload = (url: string) => {
    setUploading(false)
    if (inputRef) inputRef.value = ''
    onChange({
      target: {
        value: url,
        name,
        id: name,
      },
    })
  }

  const handleUpload = signedUpload(['png', 'jpg'], finishUpload)

  const startUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setUploading(true)
    try {
      await handleUpload(e)
    } catch (e) {
      setUploading(false)
      console.error(e)
      notify.error()
    }
  }

  return <div className={`pb-6 relative ${className}`}>
    <Label target={name}>
      {label}
      <div className="flex mt-2 items-center
                      focus:ring-dark h-12
                      cursor-pointer">
        <a href="#" tabIndex={0} onClick={click}>
          <Avatar style="big" src={value}
                  uploading={uploading}/>
        </a>
      </div>
    </Label>
    <div className="h-0 overflow-hidden">
      <input onChange={startUpload}
             disabled={uploading}
             type="file" ref={input => inputRef = input}
             id={name} tabIndex={-1}/>
    </div>
  </div>
}