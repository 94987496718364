import { RequestDocument } from 'graphql-request/dist/types'
import { query } from './query'
import { notify } from './notify'


export const handleDelete = async (
  deleteQuery: RequestDocument,
  variables: any,
  onSuccess?: () => void,
  onError?: (e: unknown) => void,
) => {
  try {
    await query(deleteQuery, variables)
    notify.success()
    if (onSuccess) onSuccess()
  } catch (e) {
    notify.error()
    console.error('Delete error', e)
    if (onError) return onError(e)
  }
}