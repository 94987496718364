import gql from 'graphql-tag';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { contextQuery } from '../utils/query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  EmailAddress: string;
  UUID: any;
};

export type AssignRoleInput = {
  email: Scalars['EmailAddress'];
  role: Role;
};

export type AssignedRole = {
  __typename?: 'AssignedRole';
  email: Scalars['EmailAddress'];
  id: Scalars['String'];
  role: Role;
};

export type Auth0Schema = {
  __typename?: 'Auth0Schema';
  audience: Scalars['String'];
  client_id: Scalars['String'];
  domain: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  email?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['UUID'];
  logo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ClientInput = {
  email?: InputMaybe<Scalars['EmailAddress']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  assignRole: AssignedRole;
  clientCreate?: Maybe<Client>;
  clientDelete?: Maybe<Scalars['Boolean']>;
  clientUpdate?: Maybe<Client>;
  generateSignedURL?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type MutationAssignRoleArgs = {
  input: AssignRoleInput;
};


export type MutationClientCreateArgs = {
  input: ClientInput;
};


export type MutationClientDeleteArgs = {
  id: Scalars['UUID'];
};


export type MutationClientUpdateArgs = {
  id: Scalars['UUID'];
  input: ClientInput;
};


export type MutationGenerateSignedUrlArgs = {
  key: Scalars['String'];
};


export type MutationUserArgs = {
  id: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  assignedRole?: Maybe<AssignedRole>;
  auth0Schema?: Maybe<Auth0Schema>;
  client?: Maybe<Client>;
  clients?: Maybe<Array<Maybe<Client>>>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAssignedRoleArgs = {
  email: Scalars['EmailAddress'];
};


export type QueryClientArgs = {
  id: Scalars['UUID'];
};


export type QueryUserArgs = {
  id: Scalars['String'];
};

export type Role =
  | 'ADMIN'
  | 'NONE'
  | 'USER';

export type User = {
  __typename?: 'User';
  email: Scalars['EmailAddress'];
  id: Scalars['String'];
  name: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  role: Role;
};

export type Auth0QueryVariables = Exact<{ [key: string]: never; }>;


export type Auth0Query = { __typename?: 'Query', auth0Schema?: { __typename?: 'Auth0Schema', audience: string, client_id: string, domain: string } | null | undefined };

export type ClientCreateMutationVariables = Exact<{
  input: ClientInput;
}>;


export type ClientCreateMutation = { __typename?: 'Mutation', clientCreate?: { __typename?: 'Client', id: any, name: string, email?: string | null | undefined } | null | undefined };

export type ClientDeleteMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ClientDeleteMutation = { __typename?: 'Mutation', clientDelete?: boolean | null | undefined };

export type ClientUpdateMutationVariables = Exact<{
  id: Scalars['UUID'];
  input: ClientInput;
}>;


export type ClientUpdateMutation = { __typename?: 'Mutation', clientUpdate?: { __typename?: 'Client', id: any, name: string, email?: string | null | undefined } | null | undefined };

export type GenerateSignedUrlMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type GenerateSignedUrlMutation = { __typename?: 'Mutation', generateSignedURL?: string | null | undefined };

export type GetClientQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetClientQuery = { __typename?: 'Query', client?: { __typename?: 'Client', id: any, email?: string | null | undefined, name: string, logo?: string | null | undefined } | null | undefined };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'Query', clients?: Array<{ __typename?: 'Client', id: any, email?: string | null | undefined, name: string, logo?: string | null | undefined } | null | undefined> | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, email: string, name: string, role: Role } | null | undefined };


export const Auth0 = gql`
    query Auth0 {
  auth0Schema {
    audience
    client_id
    domain
  }
}
    `;
export const ClientCreate = gql`
    mutation ClientCreate($input: ClientInput!) {
  clientCreate(input: $input) {
    id
    name
    email
  }
}
    `;
export const ClientDelete = gql`
    mutation ClientDelete($id: UUID!) {
  clientDelete(id: $id)
}
    `;
export const ClientUpdate = gql`
    mutation ClientUpdate($id: UUID!, $input: ClientInput!) {
  clientUpdate(id: $id, input: $input) {
    id
    name
    email
  }
}
    `;
export const GenerateSignedUrl = gql`
    mutation GenerateSignedURL($key: String!) {
  generateSignedURL(key: $key)
}
    `;
export const GetClient = gql`
    query GetClient($id: UUID!) {
  client(id: $id) {
    id
    email
    name
    logo
  }
}
    `;
export const GetClients = gql`
    query GetClients {
  clients {
    id
    email
    name
    logo
  }
}
    `;
export const Me = gql`
    query Me {
  me {
    id
    email
    name
    role
  }
}
    `;

export const Auth0Document = `
    query Auth0 {
  auth0Schema {
    audience
    client_id
    domain
  }
}
    `;
export const useAuth0Query = <
      TData = Auth0Query,
      TError = unknown
    >(
      variables?: Auth0QueryVariables,
      options?: UseQueryOptions<Auth0Query, TError, TData>
    ) =>
    useQuery<Auth0Query, TError, TData>(
      variables === undefined ? ['Auth0'] : ['Auth0', variables],
      contextQuery<Auth0Query, Auth0QueryVariables>(Auth0Document, variables),
      options
    );
export const ClientCreateDocument = `
    mutation ClientCreate($input: ClientInput!) {
  clientCreate(input: $input) {
    id
    name
    email
  }
}
    `;
export const useClientCreateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ClientCreateMutation, TError, ClientCreateMutationVariables, TContext>) =>
    useMutation<ClientCreateMutation, TError, ClientCreateMutationVariables, TContext>(
      'ClientCreate',
      (variables?: ClientCreateMutationVariables) => contextQuery<ClientCreateMutation, ClientCreateMutationVariables>(ClientCreateDocument, variables)(),
      options
    );
export const ClientDeleteDocument = `
    mutation ClientDelete($id: UUID!) {
  clientDelete(id: $id)
}
    `;
export const useClientDeleteMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ClientDeleteMutation, TError, ClientDeleteMutationVariables, TContext>) =>
    useMutation<ClientDeleteMutation, TError, ClientDeleteMutationVariables, TContext>(
      'ClientDelete',
      (variables?: ClientDeleteMutationVariables) => contextQuery<ClientDeleteMutation, ClientDeleteMutationVariables>(ClientDeleteDocument, variables)(),
      options
    );
export const ClientUpdateDocument = `
    mutation ClientUpdate($id: UUID!, $input: ClientInput!) {
  clientUpdate(id: $id, input: $input) {
    id
    name
    email
  }
}
    `;
export const useClientUpdateMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<ClientUpdateMutation, TError, ClientUpdateMutationVariables, TContext>) =>
    useMutation<ClientUpdateMutation, TError, ClientUpdateMutationVariables, TContext>(
      'ClientUpdate',
      (variables?: ClientUpdateMutationVariables) => contextQuery<ClientUpdateMutation, ClientUpdateMutationVariables>(ClientUpdateDocument, variables)(),
      options
    );
export const GenerateSignedUrlDocument = `
    mutation GenerateSignedURL($key: String!) {
  generateSignedURL(key: $key)
}
    `;
export const useGenerateSignedUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<GenerateSignedUrlMutation, TError, GenerateSignedUrlMutationVariables, TContext>) =>
    useMutation<GenerateSignedUrlMutation, TError, GenerateSignedUrlMutationVariables, TContext>(
      'GenerateSignedURL',
      (variables?: GenerateSignedUrlMutationVariables) => contextQuery<GenerateSignedUrlMutation, GenerateSignedUrlMutationVariables>(GenerateSignedUrlDocument, variables)(),
      options
    );
export const GetClientDocument = `
    query GetClient($id: UUID!) {
  client(id: $id) {
    id
    email
    name
    logo
  }
}
    `;
export const useGetClientQuery = <
      TData = GetClientQuery,
      TError = unknown
    >(
      variables: GetClientQueryVariables,
      options?: UseQueryOptions<GetClientQuery, TError, TData>
    ) =>
    useQuery<GetClientQuery, TError, TData>(
      ['GetClient', variables],
      contextQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, variables),
      options
    );
export const GetClientsDocument = `
    query GetClients {
  clients {
    id
    email
    name
    logo
  }
}
    `;
export const useGetClientsQuery = <
      TData = GetClientsQuery,
      TError = unknown
    >(
      variables?: GetClientsQueryVariables,
      options?: UseQueryOptions<GetClientsQuery, TError, TData>
    ) =>
    useQuery<GetClientsQuery, TError, TData>(
      variables === undefined ? ['GetClients'] : ['GetClients', variables],
      contextQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, variables),
      options
    );
export const MeDocument = `
    query Me {
  me {
    id
    email
    name
    role
  }
}
    `;
export const useMeQuery = <
      TData = MeQuery,
      TError = unknown
    >(
      variables?: MeQueryVariables,
      options?: UseQueryOptions<MeQuery, TError, TData>
    ) =>
    useQuery<MeQuery, TError, TData>(
      variables === undefined ? ['Me'] : ['Me', variables],
      contextQuery<MeQuery, MeQueryVariables>(MeDocument, variables),
      options
    );