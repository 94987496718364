import React from 'react'

type Props = {
  icon?: string
  spin?: boolean
  fade?: boolean
}

export const FullscreenSplash: React.FC<Props> = ({
  icon, spin, fade, children,
}) => {
  return (
    <div className="fixed inset-0 bg-dark
                    flex flex-col items-center justify-center">
      <img className="w-40" src="/logo.svg" alt=""/>
      <div className="my-8">
        <i className={`
          fa-2x text-white opacity-50 fas 
          ${spin ? 'fa-spin' : ''}
          ${fade ? 'fa-beat-fade' : ''}
          fa-${icon || 'cog'}
        `}/>
      </div>
      {children}
    </div>
  )
}