import React from 'react'
import { useSession } from '../providers/session.provider'
import { Title } from '../components/sections/title'

export const HomeSection: React.FC = () => {
  const [session] = useSession()
  return (
    <>
      <Title>Home</Title>
      <h1>{session.name}</h1>
      <h1>{session.role}</h1>
    </>
  )
}