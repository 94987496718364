import React from 'react'
import { TableInstance } from 'react-table'
import { TableHeader } from './table-header'
import { TableWrapper } from './table-wrapper'
import { Button } from '../buttons/button'

type Props = {
  // This table component can take any kind of non nested
  // table instance, this is why we force any here
  // eslint-disable-next-line
  instance: TableInstance<any>
  onRowEdit?: (id: string) => void
  onRowClick?: (id: string) => void
}

export const SimpleTable: React.FC<Props> = ({
  instance: {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
  },
  onRowEdit,
  onRowClick,
}) => {

  return (
    <TableWrapper>
      <table className={`
      min-w-full divide-y divide-gray-200 bg-white 
      ${onRowClick ? 'cursor-pointer' : ''}`}
             {...getTableProps()}>
        <TableHeader
          hasActions={Boolean(onRowEdit)}
          headerGroups={headerGroups}/>
        <tbody className="divide-y divide-gray-200" {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return <tr {...row.getRowProps()}
                       onClick={onRowClick ? () => {
                         onRowClick(row.values.id)
                       } : undefined}
                       className="hover:bg-gray-100 bg-opacity-25">
              {row.cells.map(cell =>
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  {...cell.getCellProps()}>{cell.render('Cell')}</td>,
              )}
              {onRowEdit &&
                <td className="text-right px-4">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      onRowEdit(row.original.id)
                    }}
                    label="Editar"
                    style="plain"/>
                </td>
              }
            </tr>
          })}
        </tbody>
      </table>
    </TableWrapper>
  )
}