import React, { useState } from 'react'
import { Outlet, Router } from 'react-location'
import { location, routes } from '../utils/routes'
import { Sidebar } from '../components/sidebar/sidebar'


export const DashboardSection: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <Router location={location}
            routes={routes}>
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen}/>
      {/* Main Content */}
      <div className="md:pl-64 bg-gray-100">
        <div className="p-4 md:p-8">
          <button className="md:hidden"
                  onClick={() => setSidebarOpen(!sidebarOpen)}>
            Open
          </button>
          <Outlet/>
        </div>
      </div>
    </Router>
  )
}