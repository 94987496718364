import React, { useState } from 'react'
import { auth0Client } from '../../utils/auth0'
import { emptySession, useSession } from '../../providers/session.provider'
import { SidebarItem } from './sidebar-item'
import { SidebarItemType } from './sidebar-content'

export const SidebarLogout: React.FC = () => {
  const [processing, setProcessing] = useState(false)
  const [, setSession] = useSession()

  const logout = async () => {
    if (processing) return
    setProcessing(true)
    const auth0 = await auth0Client()
    localStorage.removeItem('token')
    await auth0.logout({ federated: true })
    setProcessing(false)
    setSession(emptySession())
  }

  const logoutItem: SidebarItemType = {
    icon: 'arrow-up-left-from-circle',
    label: 'Cerrar sesión',
    path: '',
  }

  return (
    <div onClick={logout} className="mt-auto">
      <SidebarItem item={logoutItem}/>
    </div>
  )
}