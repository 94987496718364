import React from 'react'
import { HeaderGroup } from 'react-table'

type Props = {
  // eslint-disable-next-line
  headerGroups: HeaderGroup<any>[]
  hasActions?: boolean
}

const thClass =
  'px-6 py-3 text-left text-xs font-medium ' +
  'text-gray-100 uppercase tracking-wider'

export const TableHeader: React.FC<Props> = ({
  headerGroups,
  hasActions,
}) => {
  return (
    <thead className="bg-dark">
      {headerGroups.map(group => (
        <tr {...group.getHeaderGroupProps()}>
          {group.headers.map(column => (
            <th
              className={thClass}
              {...column.getHeaderProps()}>
              {column.render('Header')}
            </th>
          ))}
          {hasActions && <th className={thClass}/>}
        </tr>
      ))}
    </thead>
  )
}